<template>
    <div>
        <component is="b-card">
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('Select delivery day')"
                label-for="physical_depot"
                id="delivery"
              >
                <h6 style="color: red" class="etoile">*</h6>
                <flat-pickr
                  v-model="selecteDeliveryDay"
                  class="form-control"
                  :disabled="roles.includes('seller')"
                  :config="{
                    enableTime: false,
                    minDate: new Date().toJSON().slice(0, 10),
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('Order type')"
                label-for="order_type"
                id="type"
              >
                <h6 style="color: red" class="etoile">*</h6>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="order_type"
                  label="label"
                  v-model="preparationtype"
                  :disabled = "roles.includes('seller')"
                  input-id="order_type"
                  @input="selectedOrderType"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('Select shipping type')"
                label-for="shipping_type"
                id="shipping"
              >
                <h6 style="color: red" class="etoile">*</h6>
                <v-select
                  id="selectShipping"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="shippingTypes"
                  label="name"
                  v-model="selectedShippingType"
                  input-id="shipping_type"
                  :disabled="type === 1 || roles.includes('seller')"
                  @input="disabledOrenabledAnnexe"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('Select client')"
                label-for="client"
                id="client"
              >
                <h6 style="color: red" class="etoile">*</h6>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clients"
                  v-model="selectedClient"
                  label="name"
                  input-id="client"
                  @input="clientSelected"
                  :loading="loading"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('Select annexe')"
                label-for="annexes"
                id="annexe"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="annexes"
                  label="name"
                  v-model="selectedAnnexe"
                  input-id="annexes"
                  @input="getProductsList"
                  :disabled="disableSelectAnnexe"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('Select seller agent')"
                label-for="seller-agent"
                id="seller-agent"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sellers"
                  label="name"
                  v-model="selectedSellerAgent"
                  input-id="sellers"
                  :disabled="disableSelectSeller || roles.includes('seller')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('Select emergency degree')"
                label-for="emergency_degree"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="emergencyDegrees"
                  label="name"
                  v-model="emergencyDegree"
                  input-id="emergency_degree"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('Comment')" label-for="physical_depot">
                <b-form-input :placeholder="$t('Comment')" v-model="comment" />
              </b-form-group>
            </b-col>
          </b-row>
        </component>
        <component is="b-card">
          <b-form>
            <b-row>
              <!-- Field: search -->
              <b-col cols="12" md="12">
                <b-form-group :label="$t('Search')" label-for="search" debounce="500">
                  <b-form-input id="search" v-model="search" @input="searchCombinaison" type="search" autocomplete="off"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </component>
        <component>
          <div class="block-height scroll card-background-color" @scroll="onScroll">
            <!-- Product Details: Card Body -->
            <b-card-body class="card-color mb-1 card-padding" v-for="product in productCombinations ">
                <div class="item-groupe">
					<b-col class="item-groupe" cols="6" md="6">
                    <!-- Product Image -->
						<div class="item-img">
						  <a :href="'https://gc.azul-fish.com/'+ product.photo" :data-fancybox="product.product_name.name" :data-caption="product.product_name.name">
							<b-img
								:src="'https://gc.azul-fish.com/'+ product.photo"
								alt="photo"
								class="image-width"
							/>
						  </a>
						</div>
						<div class="item-quantity item-ml">
							<p class="fs-product mb-0">
								{{ product.product_name.name.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase()) }} 
							</p>
							<p class="item-mb fs-combinaison">
								{{ product.product_shortcut.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase()) }} 
							</p>
							<div class="d-flex align-items-center margin-bottom">
								<div class="quantity">
								  <b-input-group>
									<b-input-group-prepend>
									  <b-button variant="primary" @click="decrement(product)" class="btn-primary padding">
										<feather-icon
										  icon="MinusIcon"
										  size="12"
										/>
									  </b-button>
									</b-input-group-prepend>
									<b-form-input type="number" min="0" value="0" @input="productQuantity(product)" :id="'quantity_'+product.id" class="input-width"></b-form-input>
									<p v-if="product.selling_type.id === 2" class="unity mb-0">PCS</p>
									<p v-else class="unity mb-0">{{ product.unity.name.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())}}</p>
									<b-input-group-append>
									  <b-button variant="primary" @click="increment(product)" class="btn-primary padding">
										<feather-icon
										  icon="PlusIcon"
										  size="12"
										/>
									  </b-button>
									</b-input-group-append>
								  </b-input-group>
								</div>
								  <b-badge variant="danger" class="price-style mb-0" v-if="price[product.id]===undefined">
									Out Stock
								  </b-badge>
								  <b-badge variant="success" class="price-style mb-0" v-else>
									{{ price[product.id] }} DH
								  </b-badge>
							</div>
						</div>
					</b-col>
					<b-col cols="6" md="6">
					  <select class="form-control preparation" :id="'preparation_'+product.id" v-model="selectedPreparation[product.id]" @change="productPreparation(product)">
						<option v-for="item in preparations" :value="item.id" :key="item.id">
						  {{ item.name }}
						</option>
					  </select>
					  <!--<b-form-group
						:label="$t('Preparation')"
						label-for="preparation"
					  >
						<v-select
						  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						  :options="preparations"
						  :id="'preparation_'+product.id"
						  item-value="id"
						  item-text="id"
						  label="name"
						  v-model="selectedPreparation[product.id]"
						  @input="productPreparation(product)"
						/>
						<select class="form-control preparation" :id="'preparation_'+product.id" v-model="selectedPreparation[product.id]" @change="productPreparation(product)">
						  <option v-for="item in preparations" :value="item.id" :key="item.id">
							{{ item.name }}
						  </option>
						</select>
					  </b-form-group>-->
					</b-col>
                </div>
            </b-card-body>
          </div>
        </component>
        <br>
        <b-row>
          <b-col>
            <b-button
              :disabled="productList.length === 0 || disabled"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="saveOrder"
            >
              {{ $t("Save") }}
            </b-button>
          </b-col>
        </b-row>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    BInputGroup,
    BModal,
    VBModal,
    BCardBody,
    BImg, 
    BFormSpinbutton,
    BInputGroupPrepend,
    BInputGroupAppend,
    BBtn
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import instance from "@/libs/axios";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import Ripple from "vue-ripple-directive";
  import { BFormDatepicker, BCardText } from "bootstrap-vue";
  import $ from 'jquery'
  import { Fancybox } from "@fancyapps/ui";
  import "@fancyapps/ui/dist/fancybox/fancybox.css";
  import ConsulteDesignationPriceList from "../../settings/designation/ConsulteDesignationPriceList.vue";
  Fancybox.bind("[data-fancybox]", {
      protect: true,
      Thumbs: {
          type: "classic",
      },
  });
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BBtn,
      BModal,
      VBModal,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BForm,
      BFormGroup,
      BInputGroup,
      BFormDatepicker,
      vSelect,
      flatPickr,
      BCardBody,
      BImg,
      BFormSpinbutton,
      BInputGroupPrepend,
      BInputGroupAppend
    },
  
    directives: {
      Ripple,
    },
    async mounted() {
        try {
            this.loading = true;
            this.roles = [];
            const res = await Promise.all([
            instance.get("/parameters/clients/"),
            instance.get("/parameters/users/auth"),
            instance.get("/parameters/shipping-types/"),
            instance.get("/parameters/emergency-degrees/"),
            instance.get("/parameters/sellers/"),
			instance.get("/parameters/preparations/"),
            ]);
            this.clients = res[0].data;
            this.user = res[1].data;
            this.user_id = this.user[0].id;
            this.user[0].roles.forEach((role) => {
              this.roles.push(role.codename);
            });
            this.shippingTypes = res[2].data;
            this.emergencyDegrees = res[3].data;
            this.sellers = res[4].data;
			this.preparations = res[5].data;
            if(this.roles.includes('seller')){
              this.selectedShippingType = this.shippingTypes[0];
              this.preparationtype = this.order_type[0];
              this.selectedSellerAgent = this.user[0].first_name + " " +this.user[0].last_name;
              this.selecteDeliveryDay = new Date();
            }
        } catch (err) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: this.$t("Error"),
                text: err.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
            },
            });
        } finally {
            this.loading = false;
        }
      },
  
    data() {
      return {
        loading: null,
        disabled : false,
        preparationtype: null,
        order_type: [
          { key: "True", label: this.$t("Street sale") },
          { key: "False", label: this.$t("Store sale") },
        ],
        roles : [],
        clients: [],
        annexes: [],
        comment: null,
        selectedShippingType: null,
        selectedAnnexe: null,
        emergencyDegree: null,
        emergencyDegrees: [],
        sellers:[],
        shippingTypes: [],
        selecteDeliveryDay: null,
        selectedClient: null,
        selectedAnnexe: null,
        productCombinations: [],
        state: null,
        type_order : null,
        quantity: 0,
        piece: 0,
        price: [],
        search: null,
        productList: [],
        user:null,
        user_id:null,
        page:1,
        type: 0,
        state:null,
        type_order : null,
        sellers : [],
        selectedSellerAgent : null,
        disableSelectAnnexe : false,
        disableSelectSeller : false,
        shippingTypeId : null,
		preparations : [],
		selectedPreparation : [],
      };
    },
    
    methods: {
      async disabledOrenabledAnnexe(shippingType) {
        if(shippingType.id === 1){
          this.disableSelectAnnexe = false;
          this.shippingTypeId = 1;
          //this.selectedAnnexe = null;
        }else{
          this.disableSelectAnnexe = false;
          this.shippingTypeId = 2;
        }
      },
      async selectedOrderType(type) {
        if (type.key === "True") {
          this.type = 1;
          this.selectedShippingType = this.shippingTypes[0];
          this.disableSelectSeller = false;
        } else {
          this.selectedShippingType = null;
          this.type = 0;
          this.disableSelectSeller = true;
          this.selectedSellerAgent = null;
        }
      },
      onScroll(e){
        e.preventDefault();
        console.log(e.target.offsetHeight);
        console.log(e.target.scrollTop);
        console.log(e.target.scrollHeight);
        if((e.target.offsetHeight + e.target.scrollTop + 1) >= e.target.scrollHeight) {
          this.page=this.page+1;
          console.log(this.page);
          instance.get("/parameters/getproducts/", {
          params: {
            client_id: this.selectedClient.id,
            search:this.search,
            page :this.page,
            },
          }).then(response => {
            response.data.results.forEach((data) => {
              this.productCombinations.push(data);
			  if(this.selectedPreparation[data.id] == undefined){
				this.selectedPreparation[data.id] = 1;
			  }
            });
          });
        }
      },
      increment(product) {
        if($("#quantity_" + product.id).val()==""){
          $("#quantity_" + product.id).val(1);
        }
        else{
          $("#quantity_" + product.id).val(
              parseFloat($("#quantity_" + product.id).val()) + 1
          );
        }
        this.piece = 0;
        this.quantity = 0;
		this.preparation = 0;

        if(product.selling_type.id === 2){
          this.piece = document.getElementById("quantity_" + product.id).value;
        }else{
          this.quantity = document.getElementById("quantity_" + product.id).value;
        }
		this.preparation = document.getElementById("preparation_" + product.id).value;
        var objIndex = this.productList.findIndex((obj => obj.product_combination == product.id));
        if(objIndex !== -1){
			this.productList[objIndex].preparation = this.preparation;
            this.productList[objIndex].quantity = this.quantity;
            this.productList[objIndex].piece = this.piece;
        }else{
          if(this.price[product.id] === undefined){
            this.productList.push({
              product_combination: product.id,
			  preparation: this.preparation,
              quantity: this.quantity,
              price: 0,
              piece: this.piece,
              comment: '',
            });
          }else{
            this.productList.push({
			  product_combination: product.id,
			  preparation: this.preparation,
			  quantity: this.quantity,
			  price: this.price[product.id],
			  piece: this.piece,
			  comment: '',
			});
          }
        }
		//console.log(this.selectedPreparation);
		//console.log(this.productList);
      },
      decrement(product) {
        if($("#quantity_" + product.id).val()==""){
          $("#quantity_" + product.id).val(1);
        }else{
          if ($("#quantity_" + product.id).val() > 0) {
            $("#quantity_" + product.id).val(
                parseFloat($("#quantity_" + product.id).val()) - 1
            );
          }
        }
        this.piece = 0;
        this.quantity = 0;
		this.preparation = 0;

        if(product.selling_type.id === 2){
          this.piece = document.getElementById("quantity_" + product.id).value;
        }else{
          this.quantity = document.getElementById("quantity_" + product.id).value;
        }
		this.preparation = document.getElementById("preparation_" + product.id).value;
        var objIndex = this.productList.findIndex((obj => obj.product_combination == product.id));
        if(objIndex !== -1){
			this.productList[objIndex].preparation = this.preparation;
            this.productList[objIndex].quantity = this.quantity;
            this.productList[objIndex].piece = this.piece;
        }else{
          if(this.price[product.id] === undefined){
            this.productList.push({
              product_combination: product.id,
			  preparation: this.preparation,
              quantity: this.quantity,
              price: 0,
              piece: this.piece,
              comment: '',
            });
          }else{
            this.productList.push({
              product_combination: product.id,
			  preparation: this.preparation,
              quantity: this.quantity,
              price: this.price[product.id],
              piece: this.piece,
              comment: '',
            });
          }
        }
      },
      productQuantity(product){
        this.piece = 0;
        this.quantity = 0;
		this.preparation = 0; 

        if(product.selling_type.id === 2){
          this.piece = document.getElementById("quantity_" + product.id).value;
        }else{
          this.quantity = document.getElementById("quantity_" + product.id).value;
        }
		this.preparation = document.getElementById("preparation_" + product.id).value;
        var objIndex = this.productList.findIndex((obj => obj.product_combination == product.id));
        if(objIndex !== -1){
            this.productList[objIndex].preparation = this.preparation;
			this.productList[objIndex].quantity = this.quantity;
            this.productList[objIndex].piece = this.piece;
        }else{
          if(this.price[product.id] === undefined){
            this.productList.push({
              product_combination: product.id,
			  preparation: this.preparation,
              quantity: this.quantity,
              price: 0,
              piece: this.piece,
              comment: '',
            });
          }else{
            this.productList.push({
              product_combination: product.id,
			  preparation: this.preparation,
              quantity: this.quantity,
              price: this.price[product.id],
              piece: this.piece,
              comment: '',
            });
          }
        }
      },
	  productPreparation(product){
		this.piece = 0;
        this.quantity = 0;
		this.preparation = 0; 

        if(product.selling_type.id === 2){
          this.piece = document.getElementById("quantity_" + product.id).value;
        }else{
          this.quantity = document.getElementById("quantity_" + product.id).value;
        }
		this.preparation = document.getElementById("preparation_" + product.id).value;
        var objIndex = this.productList.findIndex((obj => obj.product_combination == product.id));
        if(objIndex !== -1){
            this.productList[objIndex].preparation = this.preparation;
			this.productList[objIndex].quantity = this.quantity;
            this.productList[objIndex].piece = this.piece;
        }else{
          if(this.price[product.id] === undefined){
            this.productList.push({
              product_combination: product.id,
			  preparation: this.preparation,
              quantity: this.quantity,
              price: 0,
              piece: this.piece,
              comment: '',
            });
          }else{
            this.productList.push({
              product_combination: product.id,
			  preparation: this.preparation,
              quantity: this.quantity,
              price: this.price[product.id],
              piece: this.piece,
              comment: '',
            });
          }
        }
      },
      async searchCombinaison(){
        this.page=1;
        const result = await instance.get("/parameters/getproducts/", {
          params: {
            client_id: this.selectedClient.id,
            search:this.search
          },
        });
		this.productCombinations = result.data.results;
		result.data.results.forEach((data) => {
		  if(this.selectedPreparation[data.id] == undefined){
		  	this.selectedPreparation[data.id] = 1;
		  }
		});
      },
  
      async saveOrder() {
        this.disabled=true;
        const result = await this.$bvModal.msgBoxConfirm(
          this.$t("Are you sure you want to save the order?"),
          {
            title: "Alert",
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (!result) {
          this.disabled=false;
          return;
        }
        if(this.roles.includes('seller')){
          this.selectedSellerAgent = this.user[0];
        }
        if (!this.selecteDeliveryDay) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("Delivery day is required"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          this.disabled=false;
          return;
        }
        if (this.productList.length===0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("Select a product first"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          this.disabled=false;
          return;
        }

        if(this.type_order === 'True'){
          if (!this.selectedAnnexe) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Alert"),
                text: this.$t("Client annexe is required"),
                icon: "InfoIcon",
                variant: "warning",
              },
            });
            this.disabled=false;
            return;
          }
          if (!this.selectedSellerAgent) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Alert"),
                text: this.$t("Seller is required"),
                icon: "InfoIcon",
                variant: "warning",
              },
            });
            this.disabled=false;
            return;
          }
        }
        if(this.type_order === 'False'){
          if (!this.selectedAnnexe) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Alert"),
                text: this.$t("Client annexe is required"),
                icon: "InfoIcon",
                variant: "warning",
              },
            });
            this.disabled=false;
            return;
          }
        }

        var objIndex = this.productList.findIndex((obj => obj.quantity == 0 && obj.piece == 0));
        if(objIndex!=-1){
          delete productList[objIndex]
        }
        if (this.emergencyDegree === null) {
          var emergencyDegree_id = null;
        } else {
          var emergencyDegree_id = this.emergencyDegree.id;
        }
        if(this.preparationtype.key === "True"){
          this.type_order = 'True';
          this.state='in_preparation';
        }else{
          this.type_order = 'False';
          this.state='received';
        }

        const data = JSON.stringify({
          client_id: this.selectedClient.id,
          client_annexe_id: this.selectedAnnexe ? this.selectedAnnexe.id : null,
          shipping_type_id: this.selectedShippingType.id,
          shipping_date: this.selecteDeliveryDay,
          type_order: this.type_order,
          comment: this.comment,
          emergency_degree_id: emergencyDegree_id,
          state : this.state,
          seller_agent:this.selectedSellerAgent ? this.selectedSellerAgent.id : null,
          products: Object.assign({}, this.productList.map((e) => ({
            ...e,
            product_combination: e.product_combination,
          }))),
        });

        try {
          const res = await instance.post("/orders/1/", data);
          if(res.data.status === 1){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Success"),
                text: this.$t("The order has been created"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.selectedClient = null;
            this.selectedAnnexe = null;
            this.selecteDeliveryDay = null;
            this.preparationtype = null;
            this.selectedShippingType = null;
            this.selectedSellerAgent = null;
            this.productList = [];
            this.productCombinations = [];
            this.price = [];
            this.disabled = false;
            this.disableSelectAnnexe = false;
            this.disableSelectSeller = false;
          }
          if(res.data.status === 0){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Error"),
                text: this.$t("An error has occurred. Please try again"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.disabled=false;
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.disabled=false;
        }
      },
      async getProductsList(){
        this.price = [];
        if(this.selectedShippingType.id === 2){
          if(this.selectedAnnexe != null && this.selectedClient != null && this.selecteDeliveryDay != null){
            try {
                const priceresult = await instance.get("/parameters/getproductsprice/", {
                  params: {
                    client_id: this.selectedClient.id,
                  },
                });
                this.price = priceresult.data;
                if(this.price ===''){
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("Alert"),
                      text: this.$t("Un erreur has been occurred plaise contact the administrator"),
                      icon: "InfoIcon",
                      variant: "warning",
                    },
                  });
                }else{
                  const result = await instance.get("/parameters/getproducts/", {
                    params: {
                      client_id: this.selectedClient.id,
                      search:this.search,
                    },
                  });
				  this.productCombinations = result.data.results;
				  result.data.results.forEach((data) => {
					if(this.selectedPreparation[data.id] == undefined){
					  this.selectedPreparation[data.id] = 1;
					}
				  });
                }
            } catch (err) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Error"),
                  text: err.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        }else{
          if(this.selectedAnnexe != null && this.selectedClient != null && this.selecteDeliveryDay != null){
            try {
                const priceresult = await instance.get("/parameters/getproductsprice/", {
                  params: {
                    client_id: this.selectedClient.id,
                  },
                });
                this.price = priceresult.data;
                if(this.price ===''){
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("Alert"),
                      text: this.$t("Un erreur has been occurred plaise contact the administrator"),
                      icon: "InfoIcon",
                      variant: "warning",
                    },
                  });
                }else{
                  const result = await instance.get("/parameters/getproducts/", {
                    params: {
                      client_id: this.selectedClient.id,
                      search:this.search,
                    },
                  });
                  this.productCombinations = result.data.results;
				  result.data.results.forEach((data) => {
					if(this.selectedPreparation[data.id] == undefined){
					  this.selectedPreparation[data.id] = 1;
				    }
				  });
                }
            } catch (err) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Error"),
                  text: err.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        }
      },
      async clientSelected(client) {
        this.getProductsList();
        this.productCombinations = [];
        this.selectedAnnexe = null;
        this.annexes = [];
        if(client!=null){
          try {
            const res = await instance.get("/parameters/clients-annexes/", {
              params: {
                all: true,
                client_id: this.selectedClient.id,
              },
            });
            this.annexes = res.data;
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Error"),
                text: err.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  h6.etoile {
    position: absolute;
    top: 0;
  }
  label#client__BV_label_ {
    margin-left: 8px;
  }
  label#delivery__BV_label_ {
    margin-left: 8px;
  }
  label#type__BV_label_ {
    margin-left: 8px;
  }
  label#shipping__BV_label_ {
    margin-left: 8px;
  }
  label#client-annexe__BV_label_ {
    margin-left: 8px;
  }
  .item-groupe {
    display: flex;
    align-items: center;
   }
    .block-height {
        height: 450px !important;
    }
    .card-body.card-color {
        background: white;
        border-radius: 0.428rem;
    }
    .item-mb{
        margin-bottom: 0.5rem !important;
    }
    .scroll {
        overflow-y: scroll
    }
    .card-padding{
        padding: 0.6rem !important;
    }
    .image-width{
        width:60px;
    }
    .fs-product {
        font-size: 14px;
        font-weight: 600;
        color: black;
    }
    .fs-combinaison {
       font-size: 11px;
    }
    .item-ml{
        margin-left:5px;
    }
    .input-width{
        width:60px !important;
    }
    .price-style.mb-0 {
        font-size: 14px !important;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        margin-left: 6px
    }
    .card-background-color{
      background-color: #f8f8f8;
    }
    .quantity {
      max-width: 240px;
    }

    .quantity input {
      text-align: center;
      height: 29px;
      border-color: #7367f0 !important;
    }
    .btn-primary.padding{
      padding: 10px;
      height: 29px;
      text-align: center;
      display: flex;
      align-items: center;
      background: white !important;
      border: 1px solid #7367f0;
      color: #7367f0 !important;
    }
    .unity {
      background: #7367f0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 3px;
      color: white;
      font-weight: 600;
      border: 1px solid #7367f0 !important;
    }
    .item-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px !important;
        overflow: hidden;
    }
    .margin-bottom{
      margin-bottom: 0.5rem;
    }
    .disktop{
      display: block;
    }
    .mobile{
      display: none;
    }

    @media only screen and (max-width: 430px) {
      .item-img {
        overflow: inherit !important;
      }
      .disktop{
        display: none !important;
      }
      .mobile{
        display: block !important;
      }
      .btn-primary.padding{
        height: 25px;
        width: 25px;
        padding: 5px;;
      }
      .price-style.mb-0 {
        font-size: 12px !important;
        height: 25px !important;
      }
      .quantity input {
        height: 25px;
        width: 50px !important;
        flex: inherit !important;
      }
      .unity {
        height: 25px;
        font-size: 8px !important;
      }
    }
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
<template>
  <div>
    <component is="b-card">
      <b-card no-body class="mb-0"
        ><b-row>
          <b-col>
            <b-form-group
              :label="$t('Select client')"
              label-for="client"
              id="client"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clients"
                v-model="selectedClient"
                label="client_name"
                input-id="client_name"
                @input="clientSelected"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('Select order')" label-for="sub-profile">
              <v-select
                @input="onSelectChange"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="sub-profile"
                v-model="OrderList"
                :options="orderInfos"
                label="ref"
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            position: relative;
            right: 0;
          "
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="SearchOrder()"
            variant="primary"
          >
            <feather-icon icon="SearchIcon" size="16" />
          </b-button>
        </div>
        <!----<b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="btn-icon"
          @click="consulteOrder(data.item)"
        >
          <feather-icon icon="SearchIcon" size="16" />
          !--Consult--
        </b-button>-->
      </b-card>
    </component>
    <component is="b-card">
      <b-card no-body class="mb-0">
        <b-table
          id="scrolle-table"
          ref="refOrderListTable"
          class="position-relative"
          :items="Orders"
          responsive
          :fields="fields"
          show-empty
          :empty-text="$t('No matching records found')"
        >
          <template #cell(price)="data">
            {{ parseFloat(data.item.price).toFixed(3) }} DH
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-if="showButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="openModal(data)"
            >
              <feather-icon icon="PlusIcon" size="16" />
            </b-button>
            <b-button
              v-else-if="orderline_id.includes(data.item.id) == false"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="openModal(data)"
            >
              <feather-icon icon="PlusIcon" size="16" />
            </b-button>

            <b-modal
              :ref="'modal-' + data.item.id"
              title="Alert"
              ok-only
              size="xl"
              centered
              no-close-on-backdrop
              ok-title="Accept"
              @ok="addOrderLine"
              @hidden="onModalHidden"
            >
              <b-row>
                <b-col cols="8">
                  <b-form-group :label="$t('Combination')" label-for="depot">
                    <v-select
                      @input="onSelectCombinaison"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="combinations"
                      @search="fetchDepotOptions"
                      v-model="selectedCombinaison"
                      label="product"
                      input-id="depot"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group :label="$t('Quantity')" label-for="quantity">
                    <b-form-input
                      :placeholder="$t('Quantity')"
                      type="number"
                      v-model="quantity"
                      input-id="quantity"
                      id="inputQuantity"
                      @input="validatequantity"
                    />
                  </b-form-group>
                </b-col>
				<b-col cols="2">
                  <b-form-group :label="$t('Sell Price')" label-for="price">
                    <b-form-input
                      :placeholder="$t('Sell Price')"
                      type="number"
                      v-model="price"
                      input-id="price"
					  readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
          </template>
        </b-table>
      </b-card>
    </component>
    <component is="b-card">
      <b-table
        :tbody-tr-class="rowClass"
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="selectedOrderLines"
        responsive
        :fields="columns"
        show-empty
        :empty-text="$t('No records has been added')"
      >
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="deleteOrderLine(data)"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </b-button>
        </template>
      </b-table>
    </component>
    <component is="b-card">
      <b-card no-body class="mb-0">
        <b-row class="mb-2">
          <!----<b-col>
            <b-form-group :label="$t('Date')">
              <flat-pickr
                style="font-size: 0.857rem"
                :value="selectedDate"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
            </b-form-group>
          </b-col>-->
          <b-col>
            <b-form-group
              :label="$t('Shipping cost')"
              label-for="Shipping_cost"
            >
              <b-form-input
                :placeholder="$t('Shipping cost')"
                type="text"
                v-model="Shipping_cost"
                input-id="Shipping_cost"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('Comment')" label-for="comment">
              <b-form-input
                :placeholder="$t('Comment')"
                type="text"
                v-model="comment"
                input-id="quantity"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              :disabled="selectedOrderLines.length === 0 || disabled"
              @click="saveDeliveryNote"
            >
              {{ $t("Transform to DN") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </component>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
  BInputGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import useAddDeliveryList from "./useAddDeliveryList";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { comment } from "postcss";

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BPagination,
    vSelect,
    BDropdown,
    BTable,
    BDropdownItem,
    BModal,
    flatPickr,
    VBModal,
    BCardText,
    BBadge,
    BInputGroup,
  },
  mounted() {
    this.OrderLists.map(function (x) {
      return (x.item_data = x.ref);
    });
  },
  data() {
    return {
      order_line_qty_enough: [],
      reference: null,
      showButton: true,
      hideButton: false,
      orderline_id: [],
      Orders: [],
      clients: [],
      depots: [],
      combinations: [],
      selectedOrderLines: [],
      orderLines: [],
      timeoutId: null,
      selectedDepot: null,
      selectedCombinaison: null,
      quantity: null,
      selectedOrderLine: null,
      Quantity_combinaison: null,
      selectedDate: new Date(),
      comment: null,
      Shipping_cost: null,
      OrderList: null,
      OrderLists: [],
      orderInfos: [],
      Selected_order: [],
      SelectedorderLines: [],
      fields: [
        { key: "ref", label: this.$t("ref") },
        { key: "client", label: this.$t("client") },
        { key: "product", label: this.$t("product") },
        { key: "available_qty", label: this.$t("available qty") },
		{ key: "price", label: this.$t("sell price") },
        { key: "actions", label: this.$t("actions") },
      ],
      columns: [
        { key: "product", label: this.$t("product") },
        { key: "depot", label: this.$t("depot") },
        { key: "qty", label: this.$t("qty") },
        { key: "actions", label: this.$t("actions") },
      ],
      selectedClient: null,
      disabled:false,
    };
  },
  created() {
    this.getOrderList();
  },

  methods: {
    validatequantity: function () {
      if (this.quantity > this.Quantity_combinaison) {
        document.getElementById("inputQuantity").classList.add("erreur");
      } else {
        document.getElementById("inputQuantity").classList.remove("erreur");
      }
    },
    onSelectCombinaison: function () {
      this.Quantity_combinaison = this.selectedCombinaison.quantity;
      if (this.quantity > this.Quantity_combinaison) {
        document.getElementById("inputQuantity").classList.add("erreur");
      } else {
        document.getElementById("inputQuantity").classList.remove("erreur");
      }
    },
    onSelectChange: function () {
      if (this.OrderList.length === 0) {
        this.selectedOrderLines = [];
        this.Orders = [];
        this.comment = null;
        this.Shipping_cost = null;
      }
      this.Selected_order = [];
      this.OrderList.forEach((order) => {
        this.Selected_order.push(order.id);
      });
    },
    async SearchOrder() {
      if (this.OrderList == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Select order first ."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
      } else {
        this.selectedOrderLines = [];
        this.Orders = [];

        try {
          const res = await instance.get("/orders/SearchOrder/", {
            params: {
              Selected_order: this.Selected_order.toString(),
            },
          });
          res.data.forEach((orderLine) => {
            const { ref, client } = orderLine.order;
            const {product_shortcut
            } = orderLine.product_combination;
            const { rest_qty, product_combination } = orderLine;
            this.Orders.push({
              ref: orderLine.order.ref,
              client: orderLine.order.client.name,
              client_id: orderLine.order.client.id,
              id: orderLine.id,
              product: product_shortcut,
              available_qty: rest_qty,
			  price: orderLine.price,
              product_combination: orderLine.product_combination,
            });
          });
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    async getOrderList() {
      this.clients = [];
      try {
        const res = await instance.get("/orders/delivery_note/", {
          params: {
            state: "ready",
          },
        });
        this.OrderLists = res.data;
        this.OrderLists.forEach((order) => {
          this.clients.push({
            client_name: order.client.name,
            client_id: order.client.id,
          });
        });
        var clients_name = [];
        clients_name = this.clients;
        var DestinctClient = clients_name.filter(
          (clients_name, index, self) =>
            index ===
            self.findIndex((t) => t.client_name === clients_name.client_name)
        );
        this.clients = DestinctClient;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async clientSelected(client) {
      this.orderInfos = [];
      this.Orders = [];
      this.selectedOrderLines = [];
      this.orderInfos = [];
      this.orderline_id = [];
      this.OrderList = null;
      this.comment = null;
      this.Shipping_cost = null;
      if (!client) return;

      try {
        const res = await instance.get("/orders/delivery_note_by_client/", {
          params: {
            client_id: this.selectedClient.client_id,
          },
        });
        res.data.forEach((order) => {
          if (order.client_annexe == null) {
            this.orderInfos.push({
              ref: order.ref + " | " + order.client.name,
              id: order.id,
            });
          } else {
            this.orderInfos.push({
              ref:
                order.ref +
                " | " +
                order.client.name +
                " | " +
                order.client_annexe.name,
              id: order.id,
            });
          }
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async saveDeliveryNote() {
      this.disabled=true;
      try {
        const resutls = await this.$bvModal.msgBoxConfirm(
          this.$t("Are you sure you want to save?"),
          {
            title: "Alert",
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (!resutls) {
          this.disabled=false;
          return;
        }

        const data = {
          order_lines: this.selectedOrderLines.map((e) => e.id),
          depots: this.selectedOrderLines.map((e) => e.depot_id),
          quantities: this.selectedOrderLines.map((e) => e.qty),
          comment: this.comment,
          Shipping_cost: this.Shipping_cost,
          entree_source_id: this.selectedOrderLines.map(
            (e) => e.entree_source_id
          ),
        };

        const res = await instance.post("/parameters/delivery-note/", data);

        if (res.data.status === 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("Delivery note has been created"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.getOrderList();
          this.Orders = [];
          this.selectedOrderLines = [];
          this.orderInfos = [];
          this.orderline_id = [];
          this.OrderList = null;
          this.comment = null;
          this.Shipping_cost = null;
          this.selectedClient = null;
          this.disabled=false;
        }
        if (res.data.status === 2) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t(
                "An unexpected error has occurred. Please try again"
              ),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          this.disabled=false;
        }
        if (res.data.status === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("Quantity not enough!"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          this.order_line_qty_enough = res.data.order_line_ids;
          this.disabled=false;
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.disabled=false;
      }
    },
    rowClass(item) {
      if (item != null) {
        if (this.order_line_qty_enough.includes(item.id)) return "table-danger";
      }
    },
    deleteOrderLine(data) {
      if (this.Orders.filter((e) => e.id === data.item.id).length > 0) {
        this.Orders = this.Orders.map((e) =>
          e.id === data.item.id
            ? {
                ...e,
                available_qty: (
                  parseFloat(e.available_qty) + parseFloat(data.item.qty)
                ).toFixed(3),
              }
            : e
        );
        this.selectedOrderLines = this.selectedOrderLines.filter(
          (e, index) => index !== data.index
        );
        this.order_line_qty_enough = [];
        const index = this.orderline_id.indexOf(data.item.id);
        if (index > -1) {
          this.orderline_id.splice(index, 1); // 2nd parameter means remove one item only
          this.order_line_qty_enough = [];
        }
      } else {
        this.Orders.push({
          ...data.item.orderLine,
          available_qty: data.item.qty,
        });

        this.selectedOrderLines = this.selectedOrderLines.filter(
          (e, index) => index !== data.index
        );
      }
    },
    onModalHidden() {
      this.quantity = null;
      this.selectedDepot = null;
      this.selectedOrderLine = null;
      this.selectedCombinaison = null;
    },
    addOrderLine(bvEvent) {
      if (!this.quantity) {
        bvEvent.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The quantity is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (!this.selectedCombinaison) {
        bvEvent.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select a combinaison"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (
        parseFloat(this.quantity) >
          parseFloat(this.selectedOrderLine.available_qty) ||
        parseFloat(this.quantity) > parseFloat(this.Quantity_combinaison)
      ) {
        bvEvent.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t(
              "The quantity must be less than or equal to the available quantity"
            ),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      var restQuantity =
        parseFloat(this.selectedOrderLine.available_qty) -
        parseFloat(this.quantity);
      if (restQuantity <= 0) {
        this.showButton = false;
        this.orderline_id.push(this.selectedOrderLine.id);
      }

      this.selectedOrderLine.available_qty = parseFloat(
        parseFloat(this.selectedOrderLine.available_qty) -
          parseFloat(this.quantity)
      ).toFixed(3);

      this.selectedOrderLines.push({
        id: this.selectedOrderLine.id,
        product: this.selectedCombinaison.combinaison,
        depot: this.selectedCombinaison.depot,
        depot_id: this.selectedCombinaison.depot_id,
        qty: parseFloat(this.quantity).toFixed(3),
        //price: this.selectedOrderLine.price,
        orderLine: this.selectedOrderLine,
        ref: this.selectedOrderLine.ref,
        entree_source_id: this.selectedCombinaison.entree_source_id,
      });

      if (
        parseFloat(this.quantity) ===
        parseFloat(this.selectedOrderLine.ordered_qty)
      ) {
        this.orderLines = this.orderLines.filter(
          (e) => e.id !== this.selectedOrderLine.id
        );
      } else {
        this.orderLines = this.orderLines.map((e) =>
          e.id === this.selectedOrderLine.id
            ? {
                ...e,
                ordered_qty: parseFloat(e.ordered_qty - this.quantity).toFixed(
                  3
                ),
              }
            : e
        );
      }
    },
    async openModal(data) {
      this.combinations = [];
      this.$refs[`modal-${data.item.id}`].show();
      this.selectedOrderLine = data.item;
      this.quantity = data.item.available_qty;
	  this.price = data.item.price;
      this.selectedCombinaison = null;
      try {
        const res = await instance.get("/orders/combinaisonInStock/", {
          params: {
            order_line_id: parseInt(data.item.id),
          },
        });

        res.data.result.forEach((combination) => {
          this.combinations.push({
            product:
			  combination[13] +
              " | " +
			  combination[14] +
              " | " +
              combination[2] +
              " | " +
              combination[3] +
              " | " +
              combination[4] +
              " | " +
              combination[5] +
              " | " +
              combination[6] +
              " | " +
              combination[7] +
              " | " +
              combination[8] +
              " | " +
              combination[9] +
              " | " +
              combination[10] +
              " | " +
              combination[1] +
              " | " +
              " QTY: " + combination[0] +
			  " | " +
              " CR: " + combination[15] + " DH",
            quantity: combination[0],
            depot: combination[1],
            depot_id: combination[11],
            entree_source_id: combination[12],
            combinaison:
              combination[2] +
              " | " +
              combination[3] +
              " | " +
              combination[4] +
              " | " +
              combination[5] +
              " | " +
              combination[6] +
              " | " +
              combination[7] +
              " | " +
              combination[8] +
              " | " +
              combination[9] +
              " | " +
              combination[10],
          });
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    fetchDepotOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/depots/", {
            params: { search },
          });
          this.depots = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      callbackFunction,
    } = useAddDeliveryList();

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      callbackFunction,
    };
  },
};
</script>

<style lang="scss">
.erreur {
  border: 1px solid red;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
